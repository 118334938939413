import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EsaAlertService } from 'src/app/utils/modules/esa-alert/esa-alert.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private baseUrl: string = environment.baseUrl;

  constructor(
    private httpClient: HttpClient,
    private esaAlertService: EsaAlertService
  ) {}

  get(route: string, options?: any): Observable<any> {
    return options
      ? this.httpClient.get(`${this.baseUrl}/api/v1/${route}`, options)
      : this.httpClient.get(`${this.baseUrl}/api/v1/${route}`);
  }

  post(route: string, value: any, options?: any): Observable<any> {
    return options
      ? this.httpClient.post(`${this.baseUrl}/api/v1/${route}`, value, options)
      : this.httpClient.post(`${this.baseUrl}/api/v1/${route}`, value);
  }

  put(route: string, value: any, options?: any): Observable<any> {
    return options
      ? this.httpClient.put(`${this.baseUrl}/api/v1/${route}`, value, options)
      : this.httpClient.put(`${this.baseUrl}/api/v1/${route}`, value);
  }

  delete(route: string, options?: any): Observable<any> {
    return options
      ? this.httpClient.delete(`${this.baseUrl}/api/v1/${route}`, options)
      : this.httpClient.delete(`${this.baseUrl}/api/v1/${route}`);
  }

  getImages(images) {
    return `${this.baseUrl}/${images}`;
  }

  baixarArquivo(url: string): Observable<Blob> {
    const urlAPI = `${this.baseUrl}/api/v1/${url}`;
    return this.httpClient.get(urlAPI, { responseType: 'blob' }).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  baixarDocumento(idVersao: string, documentName: string): Observable<Blob> {
    const urlAPI = `${this.baseUrl}/api/v1/Documentos/${idVersao}/pdf/${documentName}`;
    return this.httpClient.get(urlAPI, { responseType: 'blob' }).pipe(
      map((result: any) => {
        return result;
      })
    );
  }
  
}
